import { useEffect, useContext, useState } from "react";
import { useRouter } from "next/router";
import getConfig from "next/config";
import { useQuery, useMutation } from "react-query";
import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/types";

import {
  ApiId,
  CartLineItem,
} from "@museumofoldandnewart/digital-tessitura-client/types";

import { TessituraCartContext } from "../context/TessituraCartContext";
import { betterFetch, decomposeHost } from "./http";
import {
  PackageRemovedFromCartResult,
  PerformanceRemovedFromCartResult,
  PerformanceToRemoveFromCart,
  CartWithCheckoutResponseAndConstituentDetail,
  CheckoutInfo,
  WebSessionWithExpirations,
  PackageToRemoveFromCart,
  PerformanceAddToCartResult,
  PerformanceToAddToCart,
  MF22PerformancesResult,
  PackageDetailWithContent,
  PackageToAddToCart,
  PackageAddToCartResult,
  SponsorsQueryResult,
  CartWithFacilities,
  GoogleAnalyticsProductInfo,
} from "../lib/types";
import { ClientError, TBetterError } from "./errors";
import { DEFAULT_MODE_OF_SALE_ID } from "./constants";
import {
  analyticsEcommerceCartAdd,
  analyticsEcommercePurchase,
} from "./analyticsTrackingFunctions";

const { publicRuntimeConfig } = getConfig();

/**
 * Hook to send an error to Sentry.io.
 *
 * @param {*} error Error information, either an `Error` object or string
 */
export function useSendErrorToSentryEffect(error: Error) {
  useEffect(() => {
    if (error) {
      let level = Severity.Error;

      // Treat client errors as warnings
      if (
        error instanceof ClientError ||
        // Recognise client errors wrapped in e.g. `FetchError`
        (error as TBetterError)?.cause instanceof ClientError
      ) {
        level = Severity.Warning;
      }

      Sentry.captureException(error, {
        level,
        extra: {
          // Log addition info that might be included with error
          info: (error as TBetterError)?.info || error,
          stack: (error as TBetterError)?.fullStack || error.stack,
        },
      });
    }
  }, [error]);
}

/**
 * Redirect browser to or from the 'tickets' subdomain depending on the route
 * the browser is currently on.
 *
 * - Ensure all /tickets/* routes are served from the tickets subdomain
 * - Ensure non-/tickets/* routes are served from the main domain
 * - Let localhost serve the /tickets/ routes directly, for development
 *
 * TODO This is all a janky hack to make the tickets subdomain work during
 * development, hopefully we can scrap it once we deploy to real domains.
 */
export function useRedirectBrowserToOrFromTicketsSubdomainEffect() {
  const router = useRouter();

  const ticketsSubdomain = publicRuntimeConfig.ticketsSubdomain;

  useEffect(() => {
    if (!window) return;

    const { route, asPath } = router;

    const routeComponents = route.split("/").filter((i) => i);
    const routeTop = routeComponents[0];

    const { protocol, host } = window.location;
    const isOnTicketsSubdomain =
      publicRuntimeConfig.ticketsSubdomainsSupported.includes(
        host.split(".")[0]
      );

    if (!routeTop) {
      // Do nothing if we're at the top level of the site
    }
    // Redirect browser at any /tickets path TO the tickets subdomain
    else if (routeTop === "tickets" && !isOnTicketsSubdomain) {
      if (host.startsWith("localhost")) {
        // Permit http://localhost:3000/tickets during development
        return;
      }

      let urlPathWithoutTicketsPrefix =
        "/" +
        asPath
          .split("/")
          .filter((i) => i)
          .slice(1)
          .join("/");
      const url = `${protocol}//${ticketsSubdomain}.${host}${urlPathWithoutTicketsPrefix}`;
      window.location.replace(url);
    }
    // Redirect browser at any non-/tickets path AWAY from tickets. subdomain
    else if (
      !["tickets", "404", "500"].includes(routeTop) &&
      isOnTicketsSubdomain
    ) {
      const rootDomain = host.replace(ticketsSubdomain + ".", "");
      const url = `${protocol}//${rootDomain}${router.asPath}`;
      window.location.replace(url);
    }
  }, [router, ticketsSubdomain]);
}

export async function getCart({
  sessionKey,
  isSaved,
}: {
  sessionKey?: string;
  isSaved?: boolean;
} = {}): Promise<CartWithFacilities> {
  return betterFetch("/api/tessitura/cart", {
    params: { sessionKey, saved: isSaved },
  });
}

export function useCartQuery(
  { sessionKey, isSaved }: { sessionKey?: string; isSaved?: boolean },
  options = {}
) {
  return useQuery<CartWithFacilities, Error>(
    [`cart-${isSaved}-${sessionKey}`],
    async () => {
      return getCart({ sessionKey, isSaved });
    },
    {
      retry: 3,
      staleTime: 10000,
      refetchInterval: 10000,
      ...options,
    }
  );
}

export async function getWebSessionWithExpirations(): Promise<WebSessionWithExpirations> {
  return betterFetch("/api/tessitura/web-session");
}

export function useWebSessionWithExpirationsQuery(options = {}) {
  return useQuery<WebSessionWithExpirations, Error>(
    ["webSessionWithExpirations"],
    getWebSessionWithExpirations,
    {
      retry: 3,
      staleTime: 60000,
      ...options,
    }
  );
}

export async function fetchSponsors(): Promise<SponsorsQueryResult> {
  const response = await betterFetch("/api/sponsors");

  return {
    mediaPartners: response.mediaPartners || [],
    majorPartners: response.majorPartners || [],
  };
}

export function useSponsorsQuery(options = {}) {
  return useQuery<SponsorsQueryResult, Error>(["sponsors"], fetchSponsors, {
    retry: 3,
    staleTime: 60000,
    ...options,
  });
}

export function useAddPerformanceToCartMutation({
  ModeOfSaleId = DEFAULT_MODE_OF_SALE_ID,
  onSuccess,
}: {
  ModeOfSaleId?: ApiId;
  onSuccess?: (data: PerformanceAddToCartResult) => void;
}) {
  const { setSiteError } = useContext(TessituraCartContext);

  function buildAddPerformanceToCartData({
    primaryPerformance,
    primaryPerformanceTitle,
    ticketQuantityByPriceTypeAndZoneIdsSlug,
  }: {
    primaryPerformance: MF22PerformancesResult;
    primaryPerformanceTitle: string;
    ticketQuantityByPriceTypeAndZoneIdsSlug: Record<string, number>;
  }): PerformanceToAddToCart[] {
    return (
      Object.keys(ticketQuantityByPriceTypeAndZoneIdsSlug)
        .map((priceTypeAndZoneIdsSlug) => {
          const [priceTypeId, zoneId] = priceTypeAndZoneIdsSlug.split("-");
          const quantity =
            ticketQuantityByPriceTypeAndZoneIdsSlug[priceTypeAndZoneIdsSlug];
          const priceSummary = primaryPerformance.pricesSummary.find(
            (priceSummary) =>
              String(priceSummary.PriceTypeId) === priceTypeId &&
              String(priceSummary.ZoneId) === zoneId
          );

          return {
            performanceId: primaryPerformance.Id,
            title: primaryPerformanceTitle,
            priceTypeId: priceSummary.PriceTypeId,
            zoneId: priceSummary.ZoneId,
            price: priceSummary.Price,
            quantity,
          };
        })
        // Don't try to add price types with zero quantity
        .filter((performanceToAddToCart) => performanceToAddToCart.quantity > 0)
    );
  }

  const addPerformanceToCartMutation = useMutation<
    PerformanceAddToCartResult,
    TBetterError,
    PerformanceToAddToCart[],
    unknown
  >(
    async (performancesToAddToCart: PerformanceToAddToCart[]) => {
      return await betterFetch("/api/tessitura/cart/add-performances", {
        body: {
          ModeOfSaleId,
          performanceReservations: performancesToAddToCart,
        },
      });
    },
    {
      onSuccess: (performanceAddToCartResult) => {
        onSuccess?.(performanceAddToCartResult);

        try {
          analyticsEcommerceCartAdd({
            label: performanceAddToCartResult.added[0].title,
            parentLabel: "Performance",
            addedProducts: performanceAddToCartResult.added.map(
              (addedPerformance) => {
                return {
                  id: String(addedPerformance.performanceId),
                  name: addedPerformance.title,
                  quantity: addedPerformance.quantity,
                  price: addedPerformance.price,
                  variant: String(addedPerformance.priceTypeId),
                  brand: "MF22",
                };
              }
            ),
          });
        } catch (error) {
          Sentry.captureException(error, {
            extra: { info: performanceAddToCartResult },
          });
        }
      },
      onError: (error) => {
        setSiteError(error);
      },
    }
  );

  return { addPerformanceToCartMutation, buildAddPerformanceToCartData };
}

export function useRemovePerformanceFromCartMutation({
  onSuccess,
}: {
  onSuccess?: (data: PerformanceRemovedFromCartResult) => void;
} = {}) {
  const { setSiteError, refreshWebCart } = useContext(TessituraCartContext);

  return useMutation<
    PerformanceRemovedFromCartResult,
    TBetterError,
    PerformanceToRemoveFromCart,
    unknown
  >(
    async (data) => {
      return await betterFetch("/api/tessitura/cart/remove-performances", {
        body: data,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.removed?.length > 0) {
          // Trigger refresh of web cart for the just-removed performances
          refreshWebCart();

          onSuccess?.(data);
        }
      },
      onError: (error) => {
        setSiteError(error);
      },
    }
  );
}

export function useAddPackageToCartMutation({
  ModeOfSaleId = DEFAULT_MODE_OF_SALE_ID,
  selectedZoneIdByPerformanceId,
  onSuccess,
}: {
  ModeOfSaleId?: ApiId;
  selectedZoneIdByPerformanceId: Record<ApiId, ApiId>;
  onSuccess?: (data: PackageAddToCartResult) => void;
}) {
  const { setSiteError } = useContext(TessituraCartContext);

  function buildAddPackageToCartData({
    packageDetails,
    performances,
    ticketQuantityByPriceTypeAndZoneIdsSlug,
  }: {
    packageDetails: PackageDetailWithContent;
    performances: MF22PerformancesResult[];
    ticketQuantityByPriceTypeAndZoneIdsSlug: Record<string, number>;
  }): PackageToAddToCart[] {
    const packageId = packageDetails.Id;

    // NOTE: We asssume packages always have a single performance group
    const performanceGroupId = packageDetails.PerformanceGroupDetails[0].Id;

    // We assume performances are pre-sorted with the "primary" one first
    const primaryPerformance = performances[0];
    const secondaryPerformances = performances.slice(1);

    const packagesToAddToCart: PackageToAddToCart[] = [];

    // User selects price type quantities for only the *primary* performance...
    Object.keys(ticketQuantityByPriceTypeAndZoneIdsSlug).forEach(
      (priceTypeAndZoneIdsSlug) => {
        const [priceTypeId, zoneId] = priceTypeAndZoneIdsSlug.split("-");
        const quantity =
          ticketQuantityByPriceTypeAndZoneIdsSlug[priceTypeAndZoneIdsSlug];

        if (quantity > 0) {
          const priceSummary = primaryPerformance.pricesSummary.find(
            (priceSummary) =>
              String(priceSummary.PriceTypeId) === priceTypeId &&
              String(priceSummary.ZoneId) === zoneId
          );
          const primaryPerformancePriceTypeId = priceSummary.PriceTypeId;

          packagesToAddToCart.push({
            packageId,
            title:
              packageDetails.content.titleOverride ||
              packageDetails.content.title,
            performanceGroupId,
            performanceId: primaryPerformance.Id,
            priceTypeId: primaryPerformancePriceTypeId,
            zoneId: priceSummary.ZoneId,
            price: priceSummary.Price,
            quantity,
          });

          // ...while the *secondary* performances offer no price type choices, only
          // zone choices in a prior step, and these are added for ALL people
          // (quantities) selected for the primary ticket options
          secondaryPerformances.forEach((secondaryPerformance) => {
            const zoneId = Number(
              selectedZoneIdByPerformanceId[secondaryPerformance.Id]
            );
            packagesToAddToCart.push({
              packageId,
              title: secondaryPerformance.Description,
              performanceGroupId,
              performanceId: secondaryPerformance.Id,
              priceTypeId: primaryPerformancePriceTypeId,
              zoneId,
              price: priceSummary.Price,
              quantity,
            });
          });
        }
      }
    );

    return packagesToAddToCart;
  }

  const addPackageToCartMutation = useMutation<
    PackageAddToCartResult,
    TBetterError,
    PackageToAddToCart[],
    unknown
  >(
    async (packagesToAddToCart: PackageToAddToCart[]) => {
      return await betterFetch("/api/tessitura/cart/add-package", {
        body: {
          ModeOfSaleId,
          packageReservations: packagesToAddToCart,
        },
      });
    },
    {
      onSuccess: (packageAddToCartResult) => {
        onSuccess?.(packageAddToCartResult);

        try {
          analyticsEcommerceCartAdd({
            label: packageAddToCartResult.added[0].title,
            parentLabel: "Package",
            addedProducts: packageAddToCartResult.added.map((addedPackage) => {
              return {
                id: String(addedPackage.performanceId),
                name: addedPackage.title,
                quantity: addedPackage.quantity,
                price: addedPackage.price,
                variant: String(addedPackage.priceTypeId),
                brand: "MF22",
              };
            }),
          });
        } catch (error) {
          Sentry.captureException(error, {
            extra: { info: packageAddToCartResult },
          });
        }
      },
      onError: (error) => {
        setSiteError(error);
      },
    }
  );

  return { addPackageToCartMutation, buildAddPackageToCartData };
}

export function useRemovePackageFromCartMutation({
  onSuccess,
}: {
  onSuccess?: (data: PackageRemovedFromCartResult) => void;
} = {}) {
  const { setSiteError, refreshWebCart } = useContext(TessituraCartContext);

  return useMutation<
    PackageRemovedFromCartResult,
    TBetterError,
    PackageToRemoveFromCart,
    unknown
  >(
    async (data) => {
      return await betterFetch("/api/tessitura/cart/remove-package", {
        body: data,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.removed?.length > 0) {
          // Trigger refresh of web cart for the just-removed performances
          refreshWebCart();

          onSuccess?.(data);
        }
      },
      onError: (error) => {
        setSiteError(error);
      },
    }
  );
}

export function usePerformCheckoutMutation({
  onSuccess,
}: {
  onSuccess?: (data: CartWithCheckoutResponseAndConstituentDetail) => void;
}) {
  const { setSiteError, refreshWebCart } = useContext(TessituraCartContext);

  return useMutation<
    CartWithCheckoutResponseAndConstituentDetail,
    TBetterError,
    CheckoutInfo,
    unknown
  >(
    async (checkoutInfo) => {
      return await betterFetch("/api/tessitura/cart/checkout", {
        body: checkoutInfo,
      });
    },
    {
      onSuccess: (cart) => {
        // Trigger refresh of web cart after successful checkout
        refreshWebCart();

        onSuccess?.(cart);

        try {
          const gaProducts: GoogleAnalyticsProductInfo[] = [];
          cart.Products.forEach((cartProduct) => {
            if (cartProduct.Package) {
              // Collect all package's line items across all product-package-level entries
              const packageLineItems: CartLineItem[] =
                cartProduct.Package.LineItems;

              // Line items that have sub items: we need to show these in the UI
              const packageLineItemsWithSubItems = packageLineItems.filter(
                (packageLineItem) => packageLineItem.SubLineItems?.length > 0
              );

              packageLineItemsWithSubItems.forEach((packageLineItem) => {
                // Get info common to all products in the package
                const firstPerformance = packageLineItem.Performance;

                packageLineItem.SubLineItems.forEach((subLineItem) => {
                  gaProducts.push({
                    id: firstPerformance.Id,
                    name: firstPerformance.Description,
                    price: subLineItem.DueAmount,
                    quantity: 1,
                    variant: String(subLineItem.PriceType.Id),
                    brand: "MF22",
                  });
                });
              });
            } else if (cartProduct.Performance) {
              const firstLineItem = cartProduct.Performance.LineItem;

              gaProducts.push({
                id: firstLineItem.Performance.Id,
                name: firstLineItem.Performance.Description,
                price: firstLineItem.TotalDue,
                quantity: firstLineItem.SubLineItems.length,
                variant: String(firstLineItem.SubLineItems[0].PriceType.Id),
                brand: "MF22",
              });
            }
          });

          analyticsEcommercePurchase({
            amount: cart.AmountPaidNow,
            orderId: cart.Id,
            sourceId: cart.Source?.Id,
            deliveryMethod: cart.DeliveryMethod?.Description,
            products: gaProducts,
          });
        } catch (error) {
          Sentry.captureException(error, {
            extra: { info: cart },
          });
        }
      },
      onError: (error) => {
        setSiteError(error);
      },
    }
  );
}

export function useSiteUrlState(
  site: "program" | "tickets" | "tix" | "buy",
  path: string
) {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const { protocol, host } = window.location;
    const { rootDomain, port } = decomposeHost(host);

    const subdomain = publicRuntimeConfig.ticketsSubdomainsSupported.includes(
      site
    )
      ? publicRuntimeConfig.ticketsSubdomain + "."
      : "";

    const _path = path.startsWith("/") ? path.substr(1) : path;
    const url = `${protocol}//${subdomain}${rootDomain}${
      port ? ":" + port : ""
    }/${_path}`;

    setUrl(url);
  }, [site, path]);

  return url;
}
