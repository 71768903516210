import {
  ApiId,
  Dateish,
} from "@museumofoldandnewart/digital-tessitura-client/types";

export const TESSITURA_SESSION_KEY_COOKIE_NAME = "MONTE_CARLO";

export const TESSITURA_SOURCE_ID_COOKIE_NAME = "SOURCE";

export const MONA_FOMA_2022_SEASON_ID: ApiId = 95;

export const MONA_FOMA_2022_FROM_DATE: Dateish = "2022-01-01";

export const DEFAULT_MODE_OF_SALE_ID: ApiId = 50;

export const FERRY_ZONE_MAP_IDS: ApiId[] = [577, 955];
