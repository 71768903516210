import { useContext } from "react";

import { TessituraCartContext } from "../context/TessituraCartContext";
import { TBetterError } from "../lib/errors";
import { FetchError } from "../lib/http";
import { ErrorSummary } from "../lib/types";

export function ErrorModal({}) {
  const { siteError, setSiteError } = useContext(TessituraCartContext);

  if (!siteError) {
    return null;
  }

  const errorSummary = humanizeError(siteError);

  const CloseButton = ({ className }: { className: string }) => (
    <button onClick={() => setSiteError(null)} className={className}>
      <span className="text-4xl">X</span>
    </button>
  );

  return (
    <div
      className={
        `z-50 w-full h-full p-6 fixed top-0 left-0 bg-pink ` +
        `flex flex-col gap-4 justify-center items-center`
      }
    >
      <h2 className="uppercase text-center">
        {errorSummary.heading || "Oops"}
      </h2>
      {errorSummary.messages.map((message) => (
          <h3 key={message} className="text-center">
            {message}
          </h3>
      ))}
      <button onClick={() => setSiteError(null)} className="underline">
        <h2>{errorSummary.promptText || "Try again"}</h2>
      </button>

      <CloseButton className="absolute top-8 left-8" />
      <CloseButton className="absolute top-8 right-8" />
      <CloseButton className="absolute bottom-8 right-8" />
      <CloseButton className="absolute bottom-8 left-8" />
    </div>
  );
}

function extractMessagesFromError(error: TBetterError) {
  // Extra the (horribly deeply nested) custom error messages from our APIs
  let messages: string[] = error?.info?.data?.errors?.map((innerError) => {
    return innerError.info?.message || "";
  });

  // TODO extract messages from other error structures

  return messages;
}

export function humanizeError(error: Error): ErrorSummary {
  const DEFAULT_MESSAGES = ["Something went wrong"];

  if (error instanceof FetchError) {
    if (error.info?.status >= 400 && error.info?.status < 500) {
      // Client error (probably)
      return {
        heading: "Something's not right",
        messages: extractMessagesFromError(error) || DEFAULT_MESSAGES,
      };
    } else if (error.info?.status >= 500) {
      // Server error
      return {
        heading: "Something went wrong",
        messages: extractMessagesFromError(error) || DEFAULT_MESSAGES,
      };
    }
  }

  return {
    heading: "Oops",
    messages: DEFAULT_MESSAGES,
  };
}
