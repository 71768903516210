import { useContext, useEffect, useState } from "react";
import Link from "next/link";

import { MofoContext } from "../context/MofoContext";
import { CartContentsButton } from "./CartContentsButton";
import { ErrorModal } from "./ErrorModal";
import { memeify } from "../lib/memeify";
import { useRouter } from "next/router";
import Sponsors from "./Sponsors";
import { Search } from "./Icons";
import { H2 } from "./Styling";
import { useSiteUrlState } from "../lib/hooks";

const Layout = ({ children, sponsors = undefined }) => {
  const { lite, switchLite, isTicketsPage } = useContext(MofoContext);
  const [menuOpened, setMenuOpened] = useState(false);
  let Router = useRouter();
  let { query, pathname } = Router;

  useEffect(() => {
    if (menuOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [menuOpened]);

  const pageClassNames = isTicketsPage ? "bg-white" : "bg-yellow";
  const headerClassNames = isTicketsPage ? "bg-cyan" : "bg-yellow";

  return (
    <div
      className={`min-h-screen flex flex-col justify-start items-center text-black ${pageClassNames}`}
    >
      {/* Override global CSS body colour for tickets pages */}
      {isTicketsPage && (
        <style jsx global>{`
          body {
            background-color: #54cdd4; // cyan
          }
          a:focus,
          a:hover {
            outline-color: #54cdd4; // cyan
            outline-offset: 0.4em;
          }
        `}</style>
      )}

      <ErrorModal />

      {!["/tickets/cart", "/tickets/receipt"].includes(pathname) && (
        <CartContentsButton />
      )}
      <div
        className={
          `fixed w-full top-0 left-0 z-30 flex justify-between items-center p-4 ${headerClassNames} ` +
          `${isTicketsPage ? "border-b-2 border-black" : ""}`
        }
      >
        <div className="w-1/3">
          <button
            className="h-12 w-10 py-2 px-1  border-black flex flex-col justify-around"
            onClick={() => setMenuOpened((old) => !old)}
          >
            <span
              className={`transition duration-300 w-full bg-black h-ayo block transform ${
                menuOpened && "rotate-45 top-1.5 relative"
              }`}
            ></span>
            {!menuOpened && (
              <span className="w-full bg-black h-ayo block"></span>
            )}
            <span
              className={`transition duration-300 w-full bg-black h-ayo block transform ${
                menuOpened && "-rotate-45 -top-2.5 relative"
              }`}
            ></span>
          </button>
        </div>

        {!menuOpened && (
          <Link href="/" passHref>
            <a className="w-1/3 text-center no-underline">
              <h1 className="text-xl sm:text-4xl font-baseBloom font-normal">
                mONa fOma
              </h1>
            </a>
          </Link>
        )}

        {menuOpened && (
          <button
            className="w-1/3 text-center"
            onClick={() => setMenuOpened(false)}
          >
            <h1 className="text-xl sm:text-4xl font-baseBloom font-normal">
              {memeify("Mona Foma")}
            </h1>
          </button>
        )}

        <div className="w-1/3 flex justify-end">
          {!isTicketsPage && (
            <Link href="/search" passHref>
              <a>
                <div className="w-8">
                  <Search />
                </div>
              </a>
            </Link>
          )}
        </div>
      </div>
      <div className="pt-20 w-full relative">
        {children}

        {![
          "/",
          "/program/[slug]",
          "/tickets",
          "/tickets/[slug]",
          "/tickets/cart",
          "/tickets/receipt/[sessionKey]",
          "/program/hobart-mofo-sesh-29",
          "/program/hobart-mofo-sesh-30",
          "/program/launceston-mofo-sesh-21",
          "/program/launceston-mofo-sesh-22",
          "/subscribe",
          "/program",
        ].includes(pathname) && <Sponsors sponsors={sponsors} />}
        <div
          className={`px-6 py-2 z-20 relative text-xs flex justify-end ${
            isTicketsPage ? "bg-cyan" : "bg-yellow"
          }`}
        >
          <Link href="https://mona.net.au/privacy-policy">
            <a
              className="pr-2 mr-2 border-r border-black"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Link>
          <Link href="https://mona.net.au/terms-conditions">
            <a target="_blank" rel="noreferrer">
              Terms & Conditions
            </a>
          </Link>
        </div>
      </div>
      <div
        className={`h-screen md:w-1/2 fixed left-1/2 top-0 bg-black z-20 ${
          menuOpened ? "opacity-30 delay-150" : "opacity-0 pointer-events-none"
        } transition-all duration-300 `}
        onClick={() => setMenuOpened((o) => !o)}
      ></div>

      <div
        className={`h-screen flex flex-col items-center justify-start w-full md:w-1/2 fixed top-0 py-20 z-20 ${
          menuOpened ? "left-0" : "-left-full"
        } sm:border-r border-black ${
          isTicketsPage ? "bg-cyan" : "bg-yellow"
        } transition-all duration-300`}
        onClick={() => setMenuOpened((o) => !o)}
      >
        {isTicketsPage ? <TicketsSiteMenu /> : <ProgramSiteMenu />}
      </div>
    </div>
  );
};

export default Layout;

function ProgramSiteMenu({}) {
  const ticketsUrl = useSiteUrlState("tickets", "/");

  if (!ticketsUrl) return null;

  return (
    <>
      <h2 className="font-baseBloom mb-2">{memeify("Menu")}</h2>

      <ul className="w-full px-2 text-center text-xl leading-loose font-baseBloom">
        <li>
          <Link href="/">{memeify("Program")}</Link>
        </li>
        <li>
          <Link href="https://buy.monafoma.net.au">{memeify("Tickets")}</Link>
        </li>
        <li>
          <Link href="https://shop.mona.net.au/collections/mona-foma">
            {memeify("Merch")}
          </Link>
        </li>
        <li>
          <Link href="/page/information">{memeify("Info")}</Link>
        </li>
        <li>
          <Link href="/subscribe">{memeify("Subscribe")}</Link>
        </li>
        <li>
          <Link href="/map">{memeify("Map")}</Link>
        </li>
        <li>
          <Link href="/page/thank-you">
            <a>Partners</a>
          </Link>
        </li>
        <li>
          <Link href="https://epokhe.monafoma.net.au" passHref>
            <a target="_blank">
              <span className="uppercase">EpØkhe</span>
              {memeify(" x Mona Foma")}
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://mofo2022.s3.amazonaws.com/PDF/mona-foma-2022-planner.pdf">
            <a target="_blank" className="font-sans text-sm">
              Download the festival planner (209kb)
            </a>
          </Link>
        </li>
      </ul>
    </>
  );
}

function TicketsSiteMenu({}) {
  const programUrl = useSiteUrlState("program", "/");
  const infoUrl = useSiteUrlState("program", "/page/information");
  const subscribeUrl = useSiteUrlState("program", "/subscribe");
  const mapUrl = useSiteUrlState("program", "/map");
  const partnersUrl = useSiteUrlState("program", "/page/thank-you");

  if (!programUrl || !subscribeUrl || !mapUrl) {
    return null;
  }

  return (
    <>
      <H2 title="Menu" />

      <ul className="w-full px-2 text-center text-xl leading-loose font-baseBloom">
        <li>
          <Link href="/">{memeify("Buy tickets")}</Link>
        </li>
        <li>
          <Link href={programUrl}>{memeify("Program")}</Link>
        </li>
        <li>
          <Link href="https://shop.mona.net.au/collections/mona-foma">
            {memeify("Merch")}
          </Link>
        </li>
        <li>
          <Link href={infoUrl}>{memeify("Info")}</Link>
        </li>
        <li>
          <Link href={subscribeUrl}>{memeify("Subscribe")}</Link>
        </li>
        <li>
          <Link href={mapUrl}>{memeify("Map")}</Link>
        </li>
        <li>
          <Link href={partnersUrl}>
            <a>Partners</a>
          </Link>
        </li>
        <li>
          <Link href="https://epokhe.monafoma.net.au" passHref>
            <a target="_blank">
              <span className="uppercase">EpØkhe</span>
              {memeify(" x Mona Foma")}
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://mofo2022.s3.amazonaws.com/PDF/mona-foma-2022-planner.pdf">
            <a target="_blank" className="font-sans text-sm">
              Download the festival planner (209kb)
            </a>
          </Link>
        </li>
      </ul>
    </>
  );
}
